import Fuse from 'fuse.js'

import { Brand } from '@bff/models/og/brand'
import { Group, Product } from '@bff/models/og/menu'

import t from './actionTypes'

export type SearchState = {
  featuredProducts: Product[]
  fuseGroups: any
  fuseProducts: any
  fuseBrands: any
  error: boolean
  loading: boolean
  isSearchDrawerOpen: boolean
}

export const defaultState: SearchState = {
  featuredProducts: <Product[]>[],
  fuseGroups: <Fuse<Group>>{},
  fuseProducts: <Fuse<Product>>{},
  fuseBrands: <Fuse<Brand>>{},
  error: false,
  loading: false,
  isSearchDrawerOpen: false
}

const search = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_FUSE_PRODUCT_SEARCH:
      return {
        ...state,
        fuseProducts: action.payload
      }
    case t.SET_FUSE_GROUP_SEARCH:
      return {
        ...state,
        fuseGroups: action.payload
      }
    case t.SET_FUSE_BRAND_SEARCH:
      return {
        ...state,
        fuseBrands: action.payload
      }
    case t.OPEN_SEARCH_DRAWER:
      return {
        ...state,
        isSearchDrawerOpen: true
      }
    case t.CLOSE_SEARCH_DRAWER:
      return {
        ...state,
        isSearchDrawerOpen: false
      }
    case t.REQUEST_FEATURED_PRODUCTS_LOADING:
      return {
        ...state,
        error: false,
        loading: action.loading
      }
    case t.REQUEST_FEATURED_PRODUCTS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: action.loading
      }
    case t.SET_FEATURED_PRODUCTS:
      return {
        ...state,
        featuredProducts: action.payload
      }
    default:
      return state
  }
}

export default search
