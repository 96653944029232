import t from './actionTypes'
import { PaymentsState } from  './types'


export const defaultState: PaymentsState = {
  ach: null,
  activePaymentId: null,
  chargeDescriptor: '',
  driverTip: 0,
  paymentProviders: {}
}

export default function payments(state = defaultState, { type, payload }) {
  switch (type) {
    case t.SET_ACH:
      return {
        ...state,
        ach: payload
      }

    case t.SWITCH_PAYMENT_METHOD:
      return {
        ...state,
        activePaymentId: payload
      }

    case t.SET_PAYMENT_PROVIDERS:
      return {
        ...state,
        paymentProviders: {
          ...state.paymentProviders,
          [payload.paymentMethod]: payload
        }
      }
    case t.SET_DRIVER_TIP:
      return {
        ...state,
        driverTip: payload
      }

    default:
      return state
  }
}
