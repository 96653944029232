import window from 'global/window'

const iOSversion = /OS (\d\d?_\d(_\d)?)/
const iOS = /iPad|iPhone|iPod/
const android = /android/i
const webview = /eaze-webview/

const detectMobile = (regex: RegExp) => regex.test(window.navigator ? window.navigator.userAgent : '')

export const isIos = (() => detectMobile(iOS))()
export const isAndroid = (() => detectMobile(android))()
export const isInAppView = (() => detectMobile(webview))()
export const isIosApp = isInAppView && isIos
export const isAndroidApp = isInAppView && isAndroid

// Used in both Android and iOS. Here, we dispatch a custom event. In the app, we listen
// for the event and open the upload ID page in the browser
export const dispatchIdClickoutEvent = () => {
  // Include pathname at end of event so we can differentiate between verify, profile, menu clicks
  window.dispatchEvent(new CustomEvent(`ClickoutToUploadId${window.location.pathname}`))
}

// Since the app is the website, we need to use the app to switch the user to the broswer instead of using a regular link
// The app listens for custom events on the window https://github.com/eaze/hybrid-consumer-app/blob/latest/App.js#L80-L81
// This event triggers sending the user to the /sso page to log the user in and redirect them to the /menu page https://github.com/eaze/hybrid-consumer-app/blob/latest/App.js#L90-L97 which is the desired intent
export const dispatchAppRedirect = (redirectPath: string) => {
  window.dispatchEvent(new CustomEvent('AppRedirect', { detail: { redirectTo: redirectPath } }))
}

export const iosVersion = (() => {
  const matches = iOSversion.exec(window.navigator ? window.navigator.userAgent : '')
  if (!matches) return null
  const versionParts = matches[1].split('_').map((part) => parseInt(part))
  return {
    major: versionParts[0],
    minor: versionParts[1],
    patch: versionParts[2] || 0
  }
})()
