export default {
  SAVE_REDIRECT: 'SAVE_REDIRECT',
  SET_ADDRESS_SUGGESTIONS: 'SET_ADDRESS_SUGGESTIONS',
  SET_ADDRESS_VISIBILITY: 'SET_ADDRESS_VISIBILITY',
  SET_RESIDENTIAL_ONLY_ERROR: 'SET_RESIDENTIAL_ONLY_ERROR',
  TOGGLE_ADDRESS: 'TOGGLE_ADDRESS',
  SET_SHOULD_REDIRECT: 'SET_SHOULD_REDIRECT',
  SET_REDIRECT_LOCATION: 'SET_REDIRECT_LOCATION',
  SET_TRIGGERED_BY: 'SET_TRIGGERED_BY'
}
