/**
 * Action types for alerts
 */

import { alertTypes } from 'redux/alert/config/types'

const alertTypesMap = {
  SET_CURRENT_ACTION: 'SET_CURRENT_ACTION',
  RESET_CURRENT_ACTION:'RESET_CURRENT_ACTION'
}

Object.keys(alertTypes).forEach((alert) => {
  const showKey = `SHOW_${alert}`
  const hideKey = `HIDE_${alert}`
  alertTypesMap[showKey] = showKey
  alertTypesMap[hideKey] = hideKey
})

export default alertTypesMap
