/**
 * Address reducer
 */

import t from './actionTypes'

type Suggestion = {
  id: string
  business: boolean
  title: string
  description: string
  street: string
  zip: string
  city: string
  state: string
  body: string
  icon: string
}

export const defaultState = {
  open: false,
  suggestions: <Suggestion[]>[],
  residentialOnlyError: false,
  redirect: false,
  redirectLocation: '',
  triggeredBy: ''
}

const addressModalReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case t.TOGGLE_ADDRESS:
      return {
        ...state,
        open: !state.open
      }

    case t.SET_ADDRESS_SUGGESTIONS:
      return {
        ...state,
        suggestions: payload
      }

    case t.SET_ADDRESS_VISIBILITY:
      return {
        ...state,
        open: payload
      }

    case t.SET_RESIDENTIAL_ONLY_ERROR:
      return {
        ...state,
        residentialOnlyError: payload
      }

    case t.SET_SHOULD_REDIRECT:
      return {
        ...state,
        redirect: payload
      }

    case t.SET_REDIRECT_LOCATION:
      return {
        ...state,
        redirectLocation: payload
      }

    case t.SET_TRIGGERED_BY:
      return {
       ...state,
        triggeredBy: payload
      }
    default:
      return state
  }
}

export default addressModalReducer
