/**
 * User profile reducer
 */

import t from './actionTypes'

const defaultPwState = {
  newPassword: '',
  oldPassword: '',
  reset: false,
  token: '',
  verifyPassword: ''
}

const defaultStatusState = {
  isMobilePhoneVerified: false,
  phoneNumber: null,
  personaVerificationStatus: ''
}

export const defaultState = {
  basic: {},
  changedPassword: false,
  changedPasswordError: '',
  extra: {},
  error: null,
  password: defaultPwState,
  patient: {},
  stat: {},
  status: defaultStatusState
}

const profile = (state = defaultState, action) => {
  switch (action.type) {
    case t.REQUEST_USER_PROFILE:
      // We don't need need to reset the profile until a new poll returns a profile.
      return state

    case t.RECEIVE_USER_PROFILE:
      return {
        ...state,
        password: defaultPwState,
        ...action.profile
      }

    case t.SET_ID_ORIGIN:
      return {
        ...state,
        basic: {
          ...state.basic,
          isUsa: action.isUsa
        }
      }

    case t.SET_IDENTIFICATION_CARDTYPE:
      return {
        ...state,
        basic: {
          ...state.basic,
          eligibilityProofType: action.cardType
        }
      }

    case t.SET_IDENTIFICATION_PHOTO:
      return {
        ...state,
        basic: {
          ...state.basic,
          contentId: action.id
        }
      }

    case t.SET_RECOMMENDATION_PHOTO:
      return {
        ...state,
        patient: {
          ...state.patient,
          contentId: action.id
        }
      }

    case t.UPDATE_BASIC_VERIFICATION:
      return {
        ...state,
        basic: {
          ...state.basic,
          verificationStatus: action.payload
        }
      }

    case t.UPDATE_PATIENT_VERIFICATION:
      return {
        ...state,
        patient: {
          ...state.patient,
          verificationStatus: action.patientVerification
        }
      }

    case t.UPDATE_USER_VERIFICATION:
      return {
        ...state,
        status: {
          ...state.status,
          verificationStatus: action.userVerification
        }
      }

    case t.SAVE_PHONE_VERIFICATION:
      return {
        ...state,
        status: {
          ...state.status,
          isMobilePhoneVerified: action.payload
        }
      }

    case t.RECEIVE_USER_STATUS:
      return {
        ...state,
        basic: {
          ...state.basic,
          verificationStatus: action.verificationStatus
        }
      }

    case t.SET_COLLECTIVE_AGREEMENT:
      return {
        ...state,
        status: {
          ...state.status,
          isAgreementAccepted: true
        }
      }

    case t.NEEDS_PW_RESET:
      return {
        ...state,
        password: {
          ...state.password,
          reset: true,
          token: action.token
        }
      }

    case t.PW_RESET:
      return {
        ...state,
        password: {
          ...state.password,
          reset: false,
          token: ''
        }
      }

    case t.SET_NEW_PASSWORD:
      return {
        ...state,
        password: {
          ...state.password,
          newPassword: action.newPassword
        }
      }

    case t.SET_OLD_PASSWORD:
      return {
        ...state,
        password: {
          ...state.password,
          oldPassword: action.oldPassword
        }
      }

    case t.SET_VERIFY_PASSWORD:
      return {
        ...state,
        password: {
          ...state.password,
          verifyPassword: action.verifyPassword
        }
      }

    case t.SET_CHANGE_PASSWORD:
      return {
        ...state,
        changedPassword: action.changedPassword
      }

    case t.SET_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changedPasswordError: action.changedPasswordError
      }

    case t.RESET_PASSWORD_ERRORS:
      return {
        ...state,
        changedPassword: defaultState.changedPassword
      }

    case t.SET_PROFILE_EXTRA:
      return {
        ...state,
        extra: action.extra
      }

    case t.SET_PROFILE_BASIC:
      return {
        ...state,
        basic: action.basic
      }

    case t.SET_PROFILE_ERROR:
      return {
        ...state,
        error: action.error
      }

    case t.SET_DECLINE_REASONS:
      return {
        ...state,
        status: {
          ...state.status,
          declineReasons: action.declineReasons
        }
      }

    case t.SET_PERSONA_VERIFICATION_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          personaVerificationStatus: action.status
        }
      }

    case t.RESET_PROFILE:
      return defaultState

    default:
      return state
  }
}

export default profile
